<template>
  <div :class="generalClass">
    <div
      class="row"
      :class="$store.getters['layout/getInnerWidth'] < 900 ? 'p-2' : ''"
    >
      <div :class="getTitleClass">
        <h6 class="mt-4 title-perso-infs">
          {{ $t('views.client.details.infos.family.title') }}
        </h6>
      </div>
      <div :class="getBorderClass" class="border-bottom-title">
      </div>
      <div class="container-fluid">
        <!-- Todo when group type is added to identify member type child..spouse..
        <b-row :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs" :cols="getCols"> {{ $t('views.client.details.infos.family.spouse') }} </b-col>
          <b-col class="right-general-infs text-right"> Jeannine Parcelle</b-col>
        </b-row>
        <b-row :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs" :cols="getCols"> {{ $t('views.client.details.infos.family.children') }} </b-col>
          <b-col class="right-general-infs text-right"> 3</b-col>
        </b-row>
        -->
        <b-row :class="'mt-2 '+getMargins">
          <b-col>
            <customer-family
              :family="family"
              @on:family-member:selected="addMember"
              @on:family-member:removed="removeMember"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import {getClientFamily} from "@api/doinsport/services/client/family/family.api";
import {fromIdToIriReference, fromIriReferenceToId} from "@/utils/form";
import {getGroup, postGroup, putGroup} from "@api/doinsport/services/group/group.api";
import {refine} from "@/utils/classes";
import {getClubClient, putClubClient} from "@api/doinsport/services/client/client.api";

export default {
  props: {
    generalClass: {
      type: String,
      default: '',
    },
    client: {
      type: Object,
      default: () => {
      }
    },
  },
  data: () => ({
    innerWidth: 1600,
    family: [],
    recentlyAdded: [],
    familyMembers: null,
  }),
  components: {
    CustomerFamily: () => import('../../new/Family')
  },
  computed: {
    getMargins() {
      if (this.innerWidth < 1200) {
        return '';
      } else {
        return 'ml-3 mr-2';
      }
    },
    getCols() {
      if (this.innerWidth < 900) {
        return '';
      } else {
        return '6';
      }
    },
    getEmailCols() {
      return '';
    },
    getTitleClass() {
      if (this.innerWidth > 1592 && this.innerWidth < 1930) {
        return 'col-md-4'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1592) {
        return 'col-md-5'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-5'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-3'
      }


    },
    getBorderClass() {
      if (this.innerWidth > 1592 && this.innerWidth < 1930) {
        return 'col-md-8'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1592) {
        return 'col-md-7'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-7'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-9'
      }

    }
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    addMember(member) {
      if (this.client.familyGroup) {
        const idGroup = fromIriReferenceToId('/clubs/groups/', this.familyMembers['@id']);
        const clients = this.familyMembers.clients;

        let group = { id: idGroup, clients: clients };

        refine(group);

        group.clients.push(member.code);
        putGroup(group)
            .then((response) => {
              this.$emit('on:reload-client');
              this.loadFamily();
            })
        ;
      } else {
        getClubClient(fromIriReferenceToId('/clubs/clients/', member.code))
            .then((response) => {
              if (response.data.familyGroup) {
                const idMemberGroup = fromIriReferenceToId('/clubs/groups/', response.data.familyGroup);

                getGroup(idMemberGroup)
                  .then((response) => {
                      const memberClients = response.data.clients;
                      const iriClient = fromIdToIriReference('/clubs/clients', this.client.id);

                      memberClients.push(iriClient);

                      putGroup({id: idMemberGroup, clients: memberClients})
                        .then((response) => {
                          this.$emit('on:reload-client');
                          this.loadFamily();
                        })
                    ;
                  })
                ;
              } else {
                const group =  {
                  name: this.$t('views.client.details.infos.family.title') + ' ' + this.client.fullName,
                  club: "/clubs/" + this.$store.getters["auth/currentClub"].id,
                  type: "family",
                  clients: [
                    member.code,
                    "/clubs/clients/" + this.$route.params.id
                  ]
                };

                postGroup(group).then((response) => {
                  putClubClient({ id: this.client.id, familyGroup: response.data['@id'] })
                    .then((response) => {
                      this.$emit('on:reload-client');
                      this.loadFamily();
                    })
                  ;
                })
                ;
              }
            })
        ;
      }
    },
    removeMember(iri) {
      const idGroup = fromIriReferenceToId('/clubs/groups/', this.familyMembers['@id']);
      const clients = this.familyMembers.clients;
      const group = { id: idGroup, clients: clients };

      refine(group);

      group.clients.splice(group.clients.indexOf(iri), 1);

      putGroup(group)
          .then((response) => {
            this.$emit('on:reload-client');
            this.loadFamily();
          })
      ;
    },
    loadFamily() {
      getClientFamily(this.$route.params.id)
          .then((response) => {
            this.family = [];
            this.familyMembers = response.data;

            if (null !== response.data) {
              for (const member of response.data.clients) {
                this.family.push({
                  code: member['@id'],
                  label: member.fullName,
                  phoneNumber: member.phoneNumber,
                });
              }
            }
          })
          .finally(() => {
          })
      ;
    }
  },
  created() {
    this.innerWidth = window.innerWidth;
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    if(this.client.familyGroup) {
      this.loadFamily();
    }
  }
}
</script>
<style scoped>
.title-perso-infs {
  font: normal normal bold 21px/13px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}

@media only screen and (max-width: 1527px) {
  .title-perso-infs {
    font: normal normal bold 18px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

@media only screen and (max-width: 791px) {
  .title-perso-infs {
    font: normal normal bold 10px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

@media only screen and (max-width: 991px) {
  .title-perso-infs {
    font: normal normal bold 14px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

.border-bottom-title {
  border-bottom: 2px solid #EDF0F3;
}

.right-general-infs {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 0.5;
}

.left-general-infs {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 1;
}
</style>
