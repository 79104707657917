import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

const URL = '/clubs/groups';

export const getGroups = (groupType) => _get(URL + '?club.id='+ clubId +'&type=' + groupType);

export const getGroup = (id) => _get(URL + '/' + id);

export const deleteGroup = (id) => _delete(URL + '/' + id);

export const postGroup = (data) => _post(URL, data);

export const putGroup = (data) => _put(URL + '/' + data.id, data);
